var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-page"},[_c('datatable',{attrs:{"path":"/users","headers":[
        {text: 'ID', value: 'id'},
        {text: 'Name', value: 'name'},
        {text: 'Aktionen', value: 'actions'}
    ]},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name + ' ' + item.last_name)+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary","to":{ name: 'admin-user', params: { id: item.id } }}},[_vm._v("Ansehen")]),_c('v-btn',{staticClass:"ml-1",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.$store.dispatch('impersonateUser', item)}}},[_vm._v("Anmelden")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }